/*
|--------------------------------------------------------------------------
| Contents
|--------------------------------------------------------------------------
|
| 1.Root Variables
| 2.Global Styles
| 3.Helpers
| 4.Login Page
|
*/

/* 1.Root Variables
  ========================================================================== */
:root {
  --font-family: "Roboto", sans-serif;
  --font-size: 1rem;
  --font-size: 1rem;
  --font-weight: 400;
  --line-height: 1.5;
  --bg-color: #05084b;
  --main-color: #1b1a60;
  --text-color: #fff;
  --pink: #fa68b0;
  --white: #fff;
  --form-field-bg: #062549;
  --place-holder: #898b8e;
  --border: #586c84;
}

/* 2.Global Styles
  ========================================================================== */
body {
  background-color: var(--bg-color);
  font-family: var(--font-family);
  font-size: var(--font-size);
  font-weight: var(--font-weight);
  line-height: var(--line-height);
  color: var(--text-color);
}

/* 3.Helpers
  ========================================================================== */

/* Define css class for global loading message to cover screen during axios operations */

.loading-indicator:before {
  content: "";
  background: #000000cc;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 9999;
}

.loading-indicator:after {
  content: url("/public/images/spinner.gif");
  position: fixed;
  z-index: 9999;
  transform: translate(-50%, -50%) scale(0.5);
  top: 50%;
  left: 50%;
}

/* 4.Login Page
  ========================================================================== */
#login-container {
  background-image: url("/public/images/login-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
}

#loginLink {
  font-size: 2.5rem;
  color: var(--white);
  transition: all 0.2s linear;
}

#loginLink:hover {
  color: #7fba00;
}

@media (max-width: 1110px) {
  #loginLink {
    font-size: 1.5rem;
  }
}

/* 5. Top Navbar
  ========================================================================== */
header .top-navbar {
  background-color: var(--main-color);
  height: 61px;
  border-radius: none;
  margin: auto;
}

header .avatar {
  vertical-align: middle;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 1px solid;
  text-align: center;
  line-height: 50px;
}

.aVerticalSeparator {
  border-left: 4px solid var(--bg-color);
  /* width: 0px; */
  height: 60px;
}
.iconColor {
  background-color: var(--place-holder);
  color: var(--main-color);
}
.btn:focus {
  box-shadow: none;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: none !important;
  border-top: 0 !important;
  border-right: 0 !important;
  border-bottom: 0 !important;
  border-left: 0 !important;
}

.searchresult-bgcolor {
  background-color: var(--bg-color);
}

.dropdown-divider {
  border-top: 2px solid rgb(255, 255, 255);
  /* margin: -0.5rem 0 0.5rem 0 */
}
.dropdown-item:focus,
.dropdown-item:hover {
  background-color: #201f74;
}
.font-size20 {
  font-size: 20px;
}
/* .search-btn{
  color:var(--place-holder);
} */
/* .search-btn:hover{
  color:var(--pink)!important;
} */
.search-btn:active {
  color: var(--pink) !important;
}
.search-badge {
  font-size: 10px !important;
  background-color: var(--place-holder) !important;
}
.search-btn:hover {
  background-color: var(--pink) !important;
}
.search-badge:active {
  background-color: var(--pink) !important;
}
.search-count {
  background-color: #5ab9c9 !important;
}
.dropdown-menu {
  /* min-width:200px; */
  height: 370px;
  overflow: auto;
  scrollbar-color: #0098be #343280;
  scrollbar-width: thin;
  /* margin-left: 100px; */
}
/* .dropdown-menu{
  max-width: 440px !important;
} */
.dropdown-menu::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  cursor: pointer;
}

.dropdown-menu::-webkit-scrollbar-track {
  background-color: #343280;
}

.dropdown-menu::-webkit-scrollbar-thumb {
  background-color: #0098be;
}
.searchbar-bg {
  background-color: #343280 !important;
  /* height:45px; */
  max-width: 400px;
}
.searchicon-bg {
  background-color: #343280;
  height: 45px;
}
.searchbar-bg:focus {
  outline: none;
}
.vr {
  min-height: 1.8rem;
  /* width:4px; */
  /* color:red; */
  /* vertical-align:center; */
}
.input-search::before {
  font-family: "Font Awesome 5 Free";
  content: "\f061" !important;
  font-weight: 900;
}
::placeholder {
  color: #919196;
  opacity: 1;
  font-size: 16px;
}
::-ms-input-placeholder {
  color: #919196;
  font-size: 16px;
}
.font-size14 {
  font-size: 14px !important;
}
.err_clr {
  color: #feeb44 !important;
  font-size: 16px !important;
}
.result-count {
  top: 17px;
  left: 77px;
  background-color: #fa68b0;
}
.dropdown-item h6 {
  color: #0098be;
}
.dropdown-item li {
  background-color: #2d2c7e;
  padding: 12px 0;
}
/* .dropdown-item div:hover{
  background-color:#201f74;
} */
.dropdown-item li:hover {
  background-color: #a8d2d34a;
}
.result-key {
  color: #0098be !important;
}
.mark,
mark {
  background-color: #feeb44;
}
/* 6. Sidebar
  ========================================================================== */
aside {
  bottom: 0;
  overflow: hidden;
  position: fixed;
  top: 0px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  width: 75px;
  background-color: var(--main-color);
  z-index: 1030;
}

aside ul {
  padding: 0;
  margin: 0;
}

aside ul li a {
  display: inline-block;
  width: 100%;
  font-size: 25px;
  position: relative;
  margin-top: 15px;
  text-align: center;
  color: #cecece;
  text-decoration: none;
}

aside ul li a.active {
  color: var(--pink);
}

aside ul li a:hover {
  color: var(--pink);
}

aside ul li a > span {
  display: block;
  font-size: 14px;
  width: 100%;
}

aside ul li.logo img {
  width: 60px;
  margin-left: 8px;
  margin-top: 30px;
}

@media (max-width: 991px) {
  #desktop_panel {
    display: none;
  }

  main {
    padding: 18px 12px 0 12px !important;
    overflow-x: hidden;
  }
}

.mob-sidebar {
  z-index: 9999;
  position: fixed;
  top: 0px;
  bottom: 0;
  min-width: 250px;
  max-width: 250px;
  transition: all 0.3s;
  padding-bottom: 164px;
  overflow-y: auto;
  background-color: var(--main-color);
  border-right: 1px #0011ff solid;
  /* box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2); */
}

.mob-sidebar ul li a {
  display: block;
  padding: 0.75rem 1rem;
  color: var(--text-color);
  text-decoration: none;
}

.mob-sidebar ul li a i {
  vertical-align: middle;
}

.mob-sidebar ul li a.active {
  color: #fff;
  background: var(--pink);
}

.mob-sidebar ul li a:hover {
  color: #fff;
  background: var(--pink);
}

/* .mob-sidebar ul .active > a {
  color: #fff;
  background: var(--pink);
} */

.mob-sidebar ul ul a {
  padding-left: 2.5rem;
  background: #fff;
}

.mob-sidebar ul ul a i {
  font-size: 2em;
}

.mob-sidebar [data-toggle="collapse"] {
  position: relative;
}

.mob-sidebar [data-toggle="collapse"]:before {
  content: "expand_more";
  font-family: "Material Icons";
  font-weight: normal;
  position: absolute;
  right: 1rem;
  font-size: 24px;
  line-height: 24px;
}

.mob-sidebar [aria-expanded="true"] {
  background: #fff;
}

.mob-sidebar [aria-expanded="true"]:before {
  content: "expand_less";
}

.mob-sidebar.toggled {
  margin-left: -250px;
}

/* 7. main Layout
  ========================================================================== */
main {
  padding: 20px 20px 24px 92px;
}

main .card,
.offcanvas {
  background-color: var(--main-color);
  color: var(--text-color);
  border: 0;
  border-radius: none;
}

@media (max-width: 1200px) {
  main .card {
    margin-bottom: 16px;
  }
}

main .card-header {
  background-color: var(--main-color);
  border-bottom: 1px solid var(--border);
}

main .card-footer {
  border-top: 1px solid var(--border);
}

a {
  text-decoration: none;
}

main .table-responsive .table {
  background-color: var(--main-color);
  color: var(--text-color);
  border: 0;
}

main .table-responsive .table tbody td {
  /* color: var(--white); */
  color: #c6c6c6;
}

main .table-responsive .table tbody tr.hidden_row td {
  color: #707070;
  border-bottom: none;
}

main .form-control {
  /* background-color: var(--form-field-bg); */
  background-color: #343280 !important;
  border: none;
}

.form-control,
.form-control:focus,
.form-control:active,
.form-control:disabled {
  /* background-color: var(--form-field-bg); */
  background-color: #343280;
  outline: none;
  box-shadow: none;
  color: var(--white);
  border: none;
}

.form-control::placeholder,
.form-select:first-child {
  /* color: var(--place-holder); */
  color: #b8b8b8 !important;
}

main .form-control:disabled {
  background-color: #2c2e4f !important;
}

.form-select {
  /* background-color: var(--form-field-bg); */
  color: var(--text-color);
  /* background: var(--form-field-bg) */
  background: #343280
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='grey' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
    no-repeat right 0.75rem center/8px 10px;
}

.btn-close {
  filter: brightness(0) invert(1);
}

.custom-btn-primary {
  background-color: #6610f2;
  color: var(--white);
}
.custom-btn-pink {
  background-color: #c93381e3;
  color: var(--white);
}
.custom-btn-primary:hover,
.custom-btn-primary:focus,
.custom-btn-primary:active {
  background-color: #6610f2;
  color: var(--white);
}

.i-primary,
.i-primary:hover {
  color: var(--pink);
}

.i-secondary,
.i-secondary:hover {
  color: #1898a9;
}

.i-info {
  color: #00b6ff;
}
.i-link,
.i-link:hover {
  color: #589bff;
}

.i-success,
.i-success:hover {
  color: #01af2c;
}
.white {
  color: var(--white);
}
main .custom-border {
  border: 1px solid var(--border);
}

.form-label {
  /* color: #5ab9c9; */
  font-size: 13px;
  font-weight: lighter;
  color: #c6c6c6;
}

.modal-content {
  background: var(--main-color);
  color: var(--white);
  z-index: 9999;
}

#error_404 {
  background-image: url("/public/images/404.png");
  background-size: cover;
  background-repeat: no-repeat;
}

#error_userNotFound {
  background-image: url("/public/images/login_error.png");
  background-size: cover;
  background-repeat: no-repeat;
}

#accessDenied {
  background-image: url("/public/images/access-denied.png");
  background-size: cover;
  background-repeat: no-repeat;
}

main .table td {
  /* border-bottom: 1px solid var(--border); */
  border-bottom: 1px solid #d4d4d436;
  padding: 10px 5px;
  vertical-align: middle;
}

main .table thead {
  font-weight: normal;
  background-color: #004385;
  /* background-color: var(--border); */
  /* border-bottom: 1px solid var(--border); */
  /* color: #5ab9c9; */
  color: #45c4d6;
}
table > :not(caption) > * > * {
  border: none;
}
.table thead th {
  font-weight: 500 !important;
  padding: 15px 5px;
}
.table > :not(:first-child) {
  /* border-top: 2px solid var(--border); */
  border-top: 0;
}

.card .view-card {
  background-color: #0d0c4d;
}

.card .view-card .card-header {
  background-color: #0d0c4d;
}

/* .card .view-card .card-body {
  background-color: #7fba00;
} */

.view-card table td {
  border-bottom: none;
  background-color: #0d0c4d;
}

small {
  font-size: 11px;
}

.required:after {
  content: " *";
  color: #f63334;
}

main .table td.fit,
main .table th.fit {
  white-space: nowrap;
  width: 1%;
}

.badge {
  font-weight: 400;
  font-size: 10px;
  /* vertical-align: text-bottom; */
}

.rejected {
  color: #dc3545;
  border: 1px solid #dc3545;
  width: 60px;
  background-color: #dc35452e;
}

.code_completed {
  color: #00be66;
  border: 1px solid #00be66;
  background-color: #00be6636;
  width: 60px;
}

.SAR {
  color: #f18203;
  border: 1px solid #f18203;
  width: 60px;
  background-color: #f182033d;
}

/* React Select  */

.css-v6bks3-control {
  border: none !important;
}

.css-10ku62j {
  /* background-color: #00eaff; */
  background-color: #1b1a60;
  color: #fff !important;
  font-size: 13px;
}

.css-5dgs50 {
  background-color: #ac3a3a !important;
}

.css-1pndypt-Input {
  color: #fff !important;
}

.css-6j8wv5-Input {
  color: #fff !important;
}

.css-qc6sy-singleValue {
  color: #fff !important;
}

.user-select-none.pe-none > td {
  color: #6c757d !important;
}

.rotational-status {
  position: absolute;
  transform: rotateZ(90deg);
  right: 0;
}

.loadMore-button {
  -webkit-animation: updown 2s infinite;
  animation: updown 2s infinite;
  margin-bottom: 19px;
}

@keyframes updown {
  0% {
    transform: translate(0, 0);
  }
  20% {
    transform: translateY(10px);
  }
  40% {
    transform: translate(0, 0);
  }
}

/* Pagination CSS */

.pagination {
  margin: 15px auto;
  display: flex;
  list-style: none;
  outline: none;
}
.pagination > .active > a {
  background-color: #89c7ff;
  border-color: #89c7ff;
  color: #fff;
}
.pagination > li > a {
  /* border: 1px solid #586c84; */
  border: 1px solid #504f8f;
  /* box-shadow: 0 -5px 3px -3px #343280, 0 5px 3px -3px #343280; */
  padding: 5px 10px;
  outline: none;
  cursor: pointer;
  font-size: 13px;
}
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  /* background-color: #89c7ff; */
  background-color: #34329e;
  /* border-color: #89c7ff; */
  border-color: #34329e;
  outline: none;
}
.pagination > li > a,
.pagination > li > span {
  /* color: #89c7ff; */
  color: #504f8f;
}
.pagination > li:last-child > a {
  color: #ffffffc9;
}
.pagination > li:first-child > a,
.pagination > li:first-child > span,
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-radius: unset;
}

.showing {
  color: #89c7ff;
}

/* Sticky Table Header */

.table thead th {
  position: sticky;
  top: 0;
  /* background-color: #052e72; */
  background-color: #21206d;
  z-index: 0;
}

.table-responsive {
  max-height: 60vh;
  overflow: auto;
  scrollbar-color: #0098be #343280;
  scrollbar-width: thin;
}

.table-responsive::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  cursor: pointer;
}

.table-responsive::-webkit-scrollbar-track {
  background-color: #343280;
}

.table-responsive::-webkit-scrollbar-thumb {
  background-color: #0098be;
}

.error-table {
  max-height: 30vh;
}

/* Profile page css */

.profilepage {
  height: 100vh;
  width: 100%;
}
.col_bg_clr {
  background-color: #201f74;
}
.chart_det {
  border: 2px solid var(--main-color);
}
.tag_name {
  font-size: 11px;
  color: #6c757d;
}
.tag {
  font-size: 13px;
  color: #ffffff;
}
.no_of_counts {
  font-size: 44px;
  color: #fa68b0;
}
.project {
  border-bottom: 1px solid #6c757d !important;
}
.prj_name {
  font-size: 13px;
  color: #6c757d;
  border: 0;
  border-bottom: 3px solid var(--main-color);
  background-color: var(--main-color);
}
.prj_name:hover {
  color: #ffffff;
  /* text-decoration: #fa68b0 underline; */
  border-bottom: 3px solid#fa68b0;
}
.prj_name.active {
  color: #ffffff;
  border-bottom: 3px solid#fa68b0;
}

.prj_name:active {
  color: #ffffff;
  border-bottom: 3px solid#fa68b0;
}
.chart_label {
  font-size: 22px;
}
@property --angle {
  syntax: "<angle>";
  initial-value: 90deg;
  inherits: true;
}

@property --gradX {
  syntax: "<percentage>";
  initial-value: 50%;
  inherits: true;
}

@property --gradY {
  syntax: "<percentage>";
  initial-value: 0%;
  inherits: true;
}
:root {
  --d: 2500ms;
  --angle: 90deg;
  --gradX: 100%;
  --gradY: 50%;
  --c1: rgba(168, 239, 255, 1);
  --c2: rgba(168, 239, 255, 0.1);
}

.chart_det:hover {
  border: 2px solid;
  border-image: conic-gradient(from var(--angle), var(--c2), var(--c1) 0.1turn, var(--c1) 0.1turn, var(--c2) 0.1turn) 30;
  animation: borderRotate var(--d) linear infinite forwards;
}
@keyframes neon {
  from {
    text-shadow: 0 0 6px rgba(200, 206, 205, 0.92), 0 0 30px rgba(202, 228, 225, 0.34), 0 0 12px rgba(30, 132, 242, 0.52), 0 0 21px rgba(30, 132, 242, 0.92),
      0 0 34px rgba(30, 129, 242, 0.78), 0 0 54px rgba(30, 132, 242, 0.92);
  }
  to {
    text-shadow: 0 0 6px rgba(200, 206, 205, 0.92), 0 0 30px rgba(202, 228, 225, 0.42), 0 0 12px rgba(30, 132, 242, 0.58), 0 0 22px rgba(30, 132, 242, 0.84),
      0 0 38px rgba(30, 132, 242, 0.88), 0 0 60px rgba(30, 132, 242, 1);
  }
}

@keyframes borderRotate {
  100% {
    --angle: 420deg;
  }
}

@keyframes borderRadial {
  20% {
    --gradX: 100%;
    --gradY: 50%;
  }
  40% {
    --gradX: 100%;
    --gradY: 100%;
  }
  60% {
    --gradX: 50%;
    --gradY: 100%;
  }
  80% {
    --gradX: 0%;
    --gradY: 50%;
  }
  100% {
    --gradX: 50%;
    --gradY: 0%;
  }
}
.no_of_counts:hover {
  color: #7ddee9;
}

.Active {
  color: #00cd0e;
}

.Inactive {
  color: #f63334;
}
/* ============ */
/* Chart table css */
.count-badge-success {
  background-color: #00a056;
}
.export-bg-success {
  background-color: #006811;
}
.font-weight-light {
  font-weight: lighter !important;
}
.font-size13 {
  font-size: 13px;
}
.font-size12 {
  font-size: 12px;
}
.width-0 {
  width: 0;
}
.filter-btn-bg {
  background-color: #0098be;
}
.light-text-op1 {
  color: #ffffffc9;
}
.grey-clr {
  color: #c6c6c6;
}

/* .pagination > a{
  border-color: 1px solid #504F8F;
} */
.pagination > li:first-child > a {
  border-radius: 5px 0 0 5px;
}
.pagination > li:last-child > a {
  border-radius: 0 5px 5px 0;
}
.nav-link {
  color: #fff;
  font-size: 13px;
  position: relative;
}
.nav-pills .nav-link.active {
  color: #0098be;
  background-color: transparent;
  font-weight: 500;
}
.nav-link:hover {
  color: #0098be;
}
a:hover {
  color: #0098be;
}

.tri-img-content {
  display: none;
  position: absolute;
  z-index: 1;
  top: 30px;
}
.nav-link:hover .tri-img-content {
  display: block;
}
.nav-pills .nav-link.active .tri-img-content {
  display: block;
}
.input-bg {
  background-color: #343280 !important;
}
.close-btn-bg {
  background-color: #ff6b6b;
}
.filter-ui-bg {
  background-color: #5957b7;
}
.form-control::placeholder,
.input-search::placeholder {
  font-size: 13px;
}

.margin-top-ten {
  margin-top: 10px !important;
}

.font-size35 {
  font-size: 35px;
}

.search-bar-center {
  margin-right: 210px;
}

.height35 {
  height: 35px;
}

.css-1ohj7cf-control,
.css-1s0h01q-control {
  border-style: none !important;
  border: 0 !important;
  background-color: #343280 !important;
  max-height: 33px !important;
  border-radius: 4px !important;
  min-height: 0 !important;
  box-shadow: none;
}

.css-ovmunn-control {
  background-color: #343280 !important;
  border-radius: 4px !important;
}

.css-1840ukc-menu,
.css-9nq3y2-menu {
  background-color: #343280 !important;
  border-radius: 4px !important;
  font-size: 13px;
}

.css-yloz6m-control,
.css-1heetj2-control {
  border: 0 !important;
  background-color: #343280 !important;
}

.css-yloz6m-control:hover,
.css-1heetj2-control:hover,
.css-1s0h01q-control:hover {
  border: 0 !important;
  border-color: transparent !important;
  box-shadow: none;
}

.css-14el2xx-placeholder {
  color: #fff !important;
  font-size: 13px;
}

.css-1hb7zxy-IndicatorsContainer {
  max-height: 33px !important;
}

.input-group > .form-select {
  min-width: 100px !important;
}

.search-d-none {
  display: none;
}

@media (max-width: 576px) {
  .search {
    display: none;
  }
  .search-d-none {
    display: block;
  }
}

.td-bg-clr {
  background-color: #1b1a60;
}

.dlt-icon {
  color: #ff6b6b;
}

.dlt-icon-bg {
  background-color: #ff6b6b;
}

.violet-blue {
  color: #ae64ff;
}

input[type="checkbox"] {
  cursor: pointer;
}

input[type="checkbox"]:checked:after {
  content: "\2714";
  padding: 0 0 2px 4px;
  background-color: #39cd48;
  border-radius: 3px;
}

input[type="checkbox"]:after {
  content: " ";
  background-color: #dedede;
  height: 18px;
  width: 18px;
  color: #fff;
  display: inline-block;
  visibility: visible;
  border-radius: 3px;
}

.proj-list {
  height: 302px;
  overflow: auto;
  overflow-x: hidden;
  scrollbar-color: #0098be #343280;
  scrollbar-width: thin;
}

.proj-list::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  cursor: pointer;
}

.proj-list::-webkit-scrollbar-track {
  background-color: #4a597b;
}

.proj-list::-webkit-scrollbar-thumb {
  background-color: #302e92;
}
.proj-list button:hover {
  background-color: #4a597b;
}
.proj-list button:active {
  background-color: #4a597b;
}
.proj-list button {
  color: #fff;
  background-color: transparent;
}
.atten-log {
  height: 366px;
  overflow: auto;
  overflow-x: hidden;
  scrollbar-color: #0098be #343280;
  scrollbar-width: thin;
}
.atten-log ::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  cursor: pointer;
}

.atten-log ::-webkit-scrollbar-track {
  background-color: #343280;
}

.atten-log ::-webkit-scrollbar-thumb {
  background-color: #0098be;
}
.form-select:focus {
  border-color: transparent !important;
  outline: 0;
  box-shadow: none !important;
}
.react-datepicker-wrapper {
  padding-right: 10px;
}
.width175px {
  width: 175px !important;
}

/* ============== */
/* .table-container {
          overflow-x: auto;
          width: 60%;
          margin: auto;
      }

.font-13 {
  font-size: 13px;
}
*/

.overlay {
  /* background-color: #efefef; */
  position: absolute;
  width: 100%;
  height: 100%;
  /* z-index: 1000; */
  top: 0;
  left: 0;
  opacity: 0.5;
  /* filter: alpha(opacity=50); */
  /* display: none; */
}

.popover-body {
  height: 128px;
  overflow: auto;
  scrollbar-width: thin;
}

.popover-body::-webkit-scrollbar {
  width: 4px;
  height: 10px;
  cursor: pointer;
}

.popover-body::-webkit-scrollbar-track {
  background-color: #ffffff;
}

.popover-body::-webkit-scrollbar-thumb {
  background-color: #6a6b6b;
}
.padding30 {
  padding-left: 30px !important;
}
